import React, { useEffect, useState } from 'react'
import api from '../../apis/api'
import { Button, Input, Popconfirm, Select, Spin, Switch, message, Card, Typography, Upload } from 'antd'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { roundoff } from '../../utils/math'
import MyPagination from '../../components/MyPagination'
import { PlusCircleFilled, PlusCircleTwoTone, PlusSquareFilled, UploadOutlined, UserOutlined } from '@ant-design/icons'
let filterObj = {

}
const StrategyFutureList = () => {
    const [data, setData] = useState([])
    const [confirmLoadingStatus, setConfirmLoadingStatus] = useState(false)
    const [strFamilies, setStrFamilies] = useState([])
    const [confirmLoadingModify, setConfirmLoadingModify] = useState(false)
    const [users, setUsers] = useState([])
    const [totalFunds, setTotalFunds] = useState(undefined)

    // pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState()


    useEffect(() => {
        filterObj = { "isActive": true, "category": "futures" }
        fetchStrategiesList(1)
        fetchUsers()
        api.kite.strategies.fetchStrategyFamilies().then((res) => {
            console.log(res)
            setStrFamilies(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const fetchStrategiesList = (page) => {
        let pageObj = {
            pageNumber: page
        }
        console.log(filterObj)
        api.kite.strategies.fetchStrategies({ pageObj, filterObj }).then((res) => {
            setData(res.data)
            setTotalFunds(res.totalFund)
            setTotalPages(res.totalRecords)
        }).catch(err => {
            console.log(err)
        })
    }

    const fetchUsers = () => {
        api.users.fetch_users().then((res) => {
            setUsers(res.users)
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    const modifyStrategy = (index) => {
        setConfirmLoadingModify(true)
        const trailing_stop_loss = document.getElementById("trailing_stop_loss" + index).innerText;
        const lot = document.getElementById("lot" + index).innerText;
        const entry = document.getElementById("entry" + index).innerText;
        const exit = document.getElementById("exit" + index).innerText;
        const fund = document.getElementById("fund" + index).innerText;
        const decision = document.querySelector(`#select_decision_${index}>div>div>.ant-select-selection-item`).innerText;
        let obj = {
            "_id": data[index]._id,
            "fields": {
                "trailing_stop_loss": trailing_stop_loss,
                "decision": decision,
                "initialLots": lot,
                "entry": entry,
                "exit": exit,
                "allocated_fund": fund
            }
        }
        api.kite.strategies.modifyStrategyFutures(obj).then((res) => {
            console.log(res)
            fetchStrategiesList(currentPage)
            setConfirmLoadingModify(false)
            message.open({
                type: "success",
                content: "Strategy modified successfully"
            })
        }).catch(err => console.log(err))
    }

    const orderSlicing = (index) => {
        setConfirmLoadingModify(true)
        const trailing_stop_loss = document.getElementById("trailing_stop_loss" + index).innerText;
        const lot = document.getElementById("lot" + index).innerText;
        const entry = document.getElementById("entry" + index).innerText;
        const exit = document.getElementById("exit" + index).innerText;
        const fund = document.getElementById("fund" + index).innerText;
        const decision = document.querySelector(`#select_decision_${index}>div>div>.ant-select-selection-item`).innerText;
        const freezeLimit = document.getElementById("freezelimit" + index).innerText;
        if (lot * 2 <= freezeLimit || freezeLimit < 1 || freezeLimit === null || freezeLimit === undefined) {
            setConfirmLoadingModify(false)
            message.open({
                type: "info",
                content: "Order does not need slicing."
            })
        }
        else {
            let obj = {
                "_id": data[index]._id,
                "fields": {
                    "trailing_stop_loss": trailing_stop_loss,
                    "decision": decision,
                    "initialLots": lot,
                    "entry": entry,
                    "exit": exit,
                    "allocated_fund": fund,
                    "freezeLimit": freezeLimit
                }
            }
            api.kite.strategies.sliceFutures(obj).then((res) => {
                setConfirmLoadingModify(false)
                fetchStrategiesList(currentPage)
                message.open({
                    type: res.type,
                    content: res.msg
                })
            }).catch(err => console.log(err))
        }
    }

    const confirmStatus = (index) => {
        setConfirmLoadingStatus(true)
        let obj = {
            "_id": data[index]._id,
            "fields": {
                "isActive": !(data[index].isActive)
            }
        }
        api.kite.strategies.modifyStrategyFutures(obj).then((res) => {
            setConfirmLoadingStatus(false)
            console.log(res)
            fetchStrategiesList(currentPage)
            message.open({
                type: "success",
                content: "Status changed successfully"
            })
        }).catch(err => console.log(err))
    }

    const onPaginate = (page) => {
        console.log(page)
        setCurrentPage(page)
        setData([])
        fetchStrategiesList(page)
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setCurrentPage(1)
            filterObj = {
                ...filterObj,
                "trading_symbol": { $regex: e.target.value, $options: "i" }
            }
            setData([])
            fetchStrategiesList(1);
        }
    }

    const handleStatusChangeFilter = (e) => {
        setCurrentPage(1)
        filterObj = {
            ...filterObj, "isActive": e
        }
        setData([])
        fetchStrategiesList(1);
    }

    const formatDate = (dateStr) => {
        const [month, day, year] = dateStr.split('/');
        const formattedYear = year.length === 2 ? `20${year}` : year; // handle two-digit year
        return `${day}/${month}/${formattedYear}`;
    };

    const handleFamilyChangeFilter = (e) => {
        setCurrentPage(1)
        filterObj = {
            ...filterObj, "family": e
        }
        setData([])
        fetchStrategiesList(1);
    }

    const handleUserChangeFilter = (e) => {
        setCurrentPage(1)
        if (e === "all") {
            delete filterObj.linkedUser
        }
        else {
            filterObj = {
                ...filterObj, linkedUser: e
            }
        }
        setData([])
        fetchStrategiesList(1);
    }

    const handleFileUpload = (file) => {
        const newFileName = 'futures.csv';
        const renamedFile = new File([file], newFileName, { type: file.type });
        const formData = new FormData();
        formData.append('file', renamedFile);
        api.upload.manualEntryExitStFileUpload(formData).then((res) => {
            message.open({
                type: res.type,
                content: res.msg
            })

        }).catch(error => {
            console.error('Error uploading file:', error);
            message.error(`${file.name} file upload failed.`);
        })
        return '';
    }

    return (
        <div className='strategy_cntn'>
            <Spin className='my_loader' spinning={data && data.length === 0 ? true : false} fullscreen />
            <div className='filter_strategies_cntn_outer'>
                <div className='filter_strategies_cntn'>
                    <label><UserOutlined /> : </label>
                    {
                        users && users.length > 0 ? <Select defaultValue="all" onChange={(e) => { handleUserChangeFilter(e) }}>
                            <Select.Option key={0} value="all">All Users</Select.Option>
                            {
                                users && users.map((item, index) => (
                                    <Select.Option key={index + 1} value={item._id}>{item.name}</Select.Option>
                                ))
                            }
                        </Select> : <></>
                    }
                </div>
                <div className=''>
                    <Upload
                        name="manualCsvEntryExit"
                        maxCount={1}
                        action={handleFileUpload}
                    >
                        <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
                    </Upload>
                </div>
                <div className='total_funds_strategy'>
                    <h4>Total Funds : {totalFunds ? totalFunds : <Spin />}</h4>
                </div>
            </div>
            <div className='custom_table_cntn'>
                <table className='custom_table'>
                    <thead className='custom_table_thead'>
                        <th className='custom_table_th'>Symbol
                            <br />
                            <Input className='custom_table_th_search' size="small" placeholder='Search' onKeyUp={(e) => handleSearch(e)} />
                        </th>
                        <th className='custom_table_th'>Account</th>
                        <th className='custom_table_th'>Strategy
                            <br />
                            <Select className='filter-select-table' defaultValue={true} onChange={(e) => { handleFamilyChangeFilter(e) }}>
                                {strFamilies && strFamilies.map((item) => (
                                    <>
                                        <Select.Option
                                            key={item._id} value={item._id}>{item.name}</Select.Option >
                                    </>
                                ))}
                            </Select>
                        </th>
                        <th className='custom_table_th'>Stoploss %</th>
                        <th className='custom_table_th'>Lot Size</th>
                        <th className='custom_table_th'>Lot's </th>
                        <th className='custom_table_th'>Fund per Lot</th>
                        <th className='custom_table_th'>Total Fund</th>
                        <th className='custom_table_th'>Entry</th>
                        <th className='custom_table_th'>Exit</th>
                        <th className='custom_table_th table_th_expiry'>Expiry</th>
                        <th className='custom_table_th table_th_expiry'>Freeze Lot Limit</th>
                        <th className='custom_table_th'>Position</th>
                        <th className='custom_table_th'>Status
                            <br />
                            <Select className='filter-select-table' defaultValue={true} onChange={(e) => { handleStatusChangeFilter(e) }}>
                                <Select.Option value={true}>Active</Select.Option>
                                <Select.Option value={false}>InActive</Select.Option>
                            </Select>
                        </th>
                        <th className='custom_table_th'>Update</th>
                        <th className='custom_table_th'>Order Slicer</th>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => (
                            <tr key={index}>
                                <td className='custom_table_td'>{item.trading_symbol}</td>
                                <td className='custom_table_td'>{item.linkedUserName}</td>
                                <td className='custom_table_td' id={"familyName" + index}>{item.familyName}</td>
                                <td className='custom_table_td' id={"trailing_stop_loss" + index} contentEditable suppressContentEditableWarning={true}>{item.trailing_stop_loss}</td>
                                <td className='custom_table_td'>{item.lot_size}</td>
                                <td className='custom_table_td' id={"lot" + index} contentEditable suppressContentEditableWarning={true}>{item.initialLots}</td>
                                <td className='custom_table_td' id={"fund" + index} contentEditable suppressContentEditableWarning={true}>{item.allocated_fund}</td>
                                <td className='custom_table_td' id={"total_fund" + index} >{item.allocated_fund * item.initialLots}</td>
                                <td className='custom_table_td' id={"entry" + index} contentEditable suppressContentEditableWarning={true}>{roundoff(item.entry)}</td>
                                <td className='custom_table_td' id={"exit" + index} contentEditable suppressContentEditableWarning={true}>{roundoff(item.exit)}</td>
                                <td className='custom_table_td table_th_expiry' id={"expiry" + index}>{formatDate(item.expiry)}</td>
                                <td className='custom_table_td' contentEditable suppressContentEditableWarning={true} id={"freezelimit" + index}></td>
                                <td className='custom_table_td' id={"select_decision_" + index}><Select defaultValue={item.decision} style={{
                                    "background": item.decision === "BUY" ? "palegreen" :
                                        item.decision === "SELL" ? "#FFCCCB" : ""
                                }}>
                                    <Select.Option value="BUY">BUY</Select.Option>
                                    <Select.Option value="SELL">SELL</Select.Option>
                                </Select></td>
                                <td className='custom_table_td'><Popconfirm title="Change status"
                                    description="Are you sure?"
                                    okText="Yes"
                                    cancelText="No"
                                    okButtonProps={{
                                        loading: confirmLoadingStatus,
                                    }}
                                    onConfirm={() => confirmStatus(index)}
                                >
                                    <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={item.isActive} onChange={(e) => console.log(e)} />
                                </Popconfirm></td>
                                <td className='custom_table_td'>
                                    <Popconfirm title="Modify Strategy"
                                        description="Are you sure you want to modify this strategy?"
                                        okText="Yes"
                                        cancelText="No"
                                        okButtonProps={{
                                            loading: confirmLoadingModify,
                                        }}
                                        onConfirm={() => modifyStrategy(index)}
                                    >
                                        <Button>Modify</Button>
                                    </Popconfirm>
                                </td>
                                <td className='custom_table_td'>
                                    <Popconfirm title="Are you sure?"
                                        description="Are you sure?"
                                        okText="Yes"
                                        cancelText="No"
                                        okButtonProps={{
                                            loading: confirmLoadingModify,
                                        }}
                                        onConfirm={() => orderSlicing(index)}
                                    >
                                        <Button>Slice Order</Button>
                                    </Popconfirm>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <MyPagination current={currentPage} total={totalPages} onPaginate={(page) => onPaginate(page)} />
            <div className='use-manual'>
                <h3 style={{ textDecoration: "underline", marginLeft: "13px" }}>Order slicing</h3>
                <Typography>1. Make sure the order you want to slice is currently inactive.</Typography>
                <Typography>2. Click on the slice order button.</Typography>
                <Typography>3. If order needs to be sliced, it will get sliced and appear as multiple strategies in last page of inactive strategies.</Typography>
                <Typography>4. Now you can modify or activate/deactivate individual slices like other strategies.</Typography>
            </div>
        </div>
    )
}

export default StrategyFutureList