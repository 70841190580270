import React, { useEffect, useState } from 'react'
import api from '../../apis/api'
import { Button, Input, Popconfirm, Select, Spin, Switch, message,Card } from 'antd'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { roundoff } from '../../utils/math'
import MyPagination from '../../components/MyPagination'
import { MoneyCollectFilled, PlusCircleFilled, PlusCircleTwoTone, PlusSquareFille, UserOutlined } from '@ant-design/icons'
let filterObj = {

}
const StrategyEtfList = () => {


    const [data, setData] = useState([])
    const [confirmLoadingStatus, setConfirmLoadingStatus] = useState(false)
    const [confirmLoadingModify, setConfirmLoadingModify] = useState(false)
    const [users, setUsers] = useState([])
    const [totalFunds, setTotalFunds] = useState(undefined)

    // pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState()

    useEffect(() => {
        filterObj = { "isActive": true, "category": "etf" }
        fetchStrategiesList(1)
        fetchUsers()
    }, [])

    const fetchStrategiesList = (page) => {
        let pageObj = {
            pageNumber: page
        }
        console.log(filterObj)
        api.kite.strategies.fetchStrategies({ pageObj, filterObj }).then((res) => {
            setData(res.data)
            setTotalFunds(res.totalFund)
            setTotalPages(res.totalRecords)
        }).catch(err => {
            console.log(err)
        })
    }

    const fetchUsers = () => {
        api.users.fetch_users().then((res) => {
            setUsers(res.users)
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    const modifyStrategy = (index) => {
        setConfirmLoadingModify(true)
        const fund = document.getElementById("allocated_fund" + index).innerText;
        const trailing_stop_loss = document.getElementById("trailing_stop_loss" + index).innerText;
        const quantity = document.getElementById("quantity" + index).innerText;
        const entry = document.getElementById("entry" + index).innerText;
        const exit = document.getElementById("exit" + index).innerText;
        const decision = document.querySelector(`#select_decision_${index}>div>div>.ant-select-selection-item`).innerText;
        let obj = {
            "_id": data[index]._id,
            "fields": {
                "trailing_stop_loss": trailing_stop_loss,
                "allocated_fund": fund,
                "decision": decision,
                "quantity": quantity,
                "entry": entry,
                "exit": exit
            }
        }
        api.kite.strategies.modifyStrategy(obj).then((res) => {
            console.log(res)
            fetchStrategiesList(currentPage)
            setConfirmLoadingModify(false)
            message.open({
                type: "success",
                content: "Strategy modified successfully"
            })
        }).catch(err => console.log(err))
    }

    const confirmStatus = (index) => {
        setConfirmLoadingStatus(true)
        let obj = {
            "_id": data[index]._id,
            "fields": {
                "isActive": !(data[index].isActive)
            }
        }
        api.kite.strategies.modifyStrategy(obj).then((res) => {
            setConfirmLoadingStatus(false)
            console.log(res)
            fetchStrategiesList(currentPage)
            message.open({
                type: "success",
                content: "Status changed successfully"
            })
        }).catch(err => console.log(err))
    }

    const onPaginate = (page) => {
        console.log(page)
        setCurrentPage(page)
        setData([])
        fetchStrategiesList(page)
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setCurrentPage(1)
            filterObj = {
                ...filterObj,
                "trading_symbol": { $regex: e.target.value, $options: "i" }
            }
            setData([])
            fetchStrategiesList(1);
        }
    }

    const handleStatusChangeFilter = (e) => {
        setCurrentPage(1)
        filterObj = {
            ...filterObj, "isActive": e
        }
        setData([])
        fetchStrategiesList(1);
    }

    const handleCategoryChangeFilter = (e) => {
        console.log(e)
        setCurrentPage(1)
        if (e === "both") {
            filterObj = {
                ...filterObj, category: "etf"
            }
        }
        else {
            filterObj = {
                ...filterObj, category: [e]
            }
        }
        setData([])
        fetchStrategiesList(1);
    }

    const handleUserChangeFilter = (e) => {
        setCurrentPage(1)
        if (e === "all") {
            delete filterObj.linkedUser
        }
        else {
            filterObj = {
                ...filterObj, category: "etf", linkedUser: e
            }
        }
        setData([])
        fetchStrategiesList(1);
    }


    return (
        <div className='strategy_cntn'>
            <Spin className='my_loader' spinning={data && data.length === 0 ? true : false} fullscreen />
            <div className='filter_strategies_cntn_outer'>
            <div className='filter_strategies_cntn'>
                <label><UserOutlined /> : </label>
                {
                    users && users.length > 0 ? <Select defaultValue="all" onChange={(e) => { handleUserChangeFilter(e) }}>
                        <Select.Option key={0} value="all">All Users</Select.Option>
                        {
                            users && users.map((item, index) => (
                                <Select.Option key={index + 1} value={item._id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select> : <></>
                }
            </div>
            <div className='total_funds_strategy'>
            <h4>Total Funds : {totalFunds ? totalFunds:<Spin/>}</h4>
            </div>
            </div>
            <div className='custom_table_cntn'>
                <table className='custom_table'>
                    <thead className='custom_table_thead'>
                        <th className='custom_table_th'>Symbol
                            <br />
                            <Input className='custom_table_th_search' size="small" placeholder='Search' onKeyUp={(e) => handleSearch(e)} />
                        </th>
                        <th className='custom_table_th'>Account</th>
                        <th className='custom_table_th'>Strategy</th>
                        <th className='custom_table_th'>Stoploss %</th>
                        <th className='custom_table_th'>Quantity</th>
                        <th className='custom_table_th'>Entry</th>
                        <th className='custom_table_th'>Exit</th>
                        <th className='custom_table_th'>Allocated Fund</th>
                        <th className='custom_table_th'>Position</th>
                        <th className='custom_table_th'>Status
                            <br />
                            <Select className='filter-select-table' defaultValue={true} onChange={(e) => { handleStatusChangeFilter(e) }}>
                                <Select.Option value={true}>Active</Select.Option>
                                <Select.Option value={false}>InActive</Select.Option>
                            </Select>
                        </th>
                        <th className='custom_table_th'>Update</th>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => (
                            <tr key={index}>
                                <td className='custom_table_td'>{item.trading_symbol}</td>
                                <td className='custom_table_td'>{item.linkedUserName}</td>
                                <td className='custom_table_td' id={"familyName" + index}>{item.familyName}</td>
                                <td className='custom_table_td' id={"trailing_stop_loss" + index} contentEditable suppressContentEditableWarning={true}>{item.trailing_stop_loss}</td>
                                <td className='custom_table_td' id={"quantity" + index} contentEditable suppressContentEditableWarning={true}>{item.quantity}</td>
                                <td className='custom_table_td' id={"entry" + index} contentEditable suppressContentEditableWarning={true}>{roundoff(item.entry)}</td>
                                <td className='custom_table_td' id={"exit" + index} contentEditable suppressContentEditableWarning={true}>{roundoff(item.exit)}</td>
                                <td className='custom_table_td' id={"allocated_fund" + index} contentEditable suppressContentEditableWarning={true}>{item.allocated_fund}</td>
                                <td className='custom_table_td' id={"select_decision_" + index}><Select defaultValue={item.decision} style={{ "background": item.decision === "BUY" ? "palegreen" : "#FFCCCB" }}>
                                    <Select.Option value="BUY">BUY</Select.Option>
                                    <Select.Option value="SELL">SELL</Select.Option>
                                </Select></td>
                                <td className='custom_table_td'><Popconfirm title="Change status"
                                    description="Are you sure to delete this task?"
                                    okText="Yes"
                                    cancelText="No"
                                    okButtonProps={{
                                        loading: confirmLoadingStatus,
                                    }}
                                    onConfirm={() => confirmStatus(index)}
                                >
                                    <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={item.isActive} onChange={(e) => console.log(e)} />
                                </Popconfirm></td>
                                <td className='custom_table_td'>
                                    <Popconfirm title="Modify Strategy"
                                        description="Are you sure you want to modify this strategy?"
                                        okText="Yes"
                                        cancelText="No"
                                        okButtonProps={{
                                            loading: confirmLoadingModify,
                                        }}
                                        onConfirm={() => modifyStrategy(index)}
                                    >
                                        <Button>Modify</Button>
                                    </Popconfirm>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <MyPagination current={currentPage} total={totalPages} onPaginate={(page) => onPaginate(page)} />
        </div>
    )
}

export default StrategyEtfList