import { useEffect, useState } from "react";
import MyTable from "../components/MyTable";
import api from "../apis/api";
import { Card, Col, Radio, Row } from "antd";

const Monitoring = () => {

    const [liveData, setLiveData] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //     try {
    //         setIsLoading(true)
    //         const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_LINK);
    //         fetchMarketLivedata().then((res) => {
    //             if (res.msg === "success") {
    //                 setIsLoading(false)
    //                 socket.onopen = () => {
    //                     console.log('Connected to WebSocket server');
    //                 };

    //                 socket.onmessage = (event) => {
    //                     const data = JSON.parse(event.data);
    //                     setLiveData(data.liveData);
    //                     console.log(data)
    //                 };

    //                 socket.onclose = () => {
    //                     console.log('Disconnected from WebSocket server');
    //                 };
    //             }
    //         }).catch(err => {
    //             setIsLoading(false)
    //             console.log(err)
    //         })
    //         return () => {
    //             if (socket) {
    //                 socket.close();
    //             }
    //         };
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }

    // }, [])

    // const fetchMarketLivedata = (data) => {
    //     return api.kite.trading.fetchLiveData(data);
    // }


    // const onChange = (e) => {
    //     setIsLoading(true)
    //     fetchMarketLivedata({ "security": e.target.value }).then((res) => {
    //         setIsLoading(false)
    //     }).catch(err => console.log(err))
    // }



    return (
        <div>
            <h1>I'll be back in sometime.</h1>
            {/* <Row style={{ marginBottom: '10px' }}>
                <Col span={8} offset={8}>
                    <div className="ant-filtertabs">
                        <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                            <Radio.Group onChange={onChange} defaultValue="stocks">
                                <Radio.Button value="stocks">STOCK</Radio.Button>
                                <Radio.Button value="etf">ETF</Radio.Button>

                            </Radio.Group>
                        </div>
                    </div>
                </Col>
            </Row>
            <MyTable
                loading={isLoading}
                data={liveData}
            /> */}
        </div>
    )
}

export default Monitoring