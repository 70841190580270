import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Card, InputNumber, Switch, message, Select } from 'antd';
import { CalculatorFilled, MoneyCollectFilled, MoneyCollectTwoTone, PercentageOutlined, PlusCircleFilled, } from '@ant-design/icons';
import api from '../../apis/api';
import insTokens from '../../assets/variables/instruments';
import getInstrumentId from '../../utils/getInstrumentId';

const StrategyFutures = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [strFamilies, setStrFamilies] = useState([])
    const [users, setUsers] = useState([])
    const [instrumentList, setInstrumentList] = useState(insTokens.stocks)

    const onFinish = values => {
        values = {
            ...values, familyName: fetchStrategyName(values.family),isActive:false
        }
        setIsLoading(true)
        console.log(values)
        api.kite.strategies.addStrategyFutures(values).then((res) => {
            message.success(res.msg)
            setIsLoading(false)
            form.resetFields()
        }).catch(err => {
            message.error(err)
            setIsLoading(false)
        })
    };

    const fetchStrategyName = id => {
        let foundStrategy = strFamilies.find(obj => obj['_id'] === id)
        return foundStrategy.name
    }

    useEffect(() => {
        api.kite.strategies.fetchStrategyFamilies().then((res) => {
            console.log(res)
            setStrFamilies(res.data)
        }).catch(err => {
            console.log(err)
        })

        api.users.fetch_users().then((res) => {
            console.log(res)
            setUsers(res.users)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <Card
            title="Design Strategy"
            extra={<CalculatorFilled />}
        >
            <Form
                form={form}
                name="my_form"
                onFinish={onFinish}
                layout="vertical"
            // initialValues={{
            //   field1: '',
            //   field2: '',
            //   field3: '',
            //   field4: '',
            // }}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12} >
                        <Form.Item
                            name="family"
                            label="Strategy Family"
                            rules={[{ required: true, message: 'Please select strategy family!' }]}

                        >
                            <Select size='large' style={{ ':hover': { borderColor: "black" } }}>
                                {strFamilies && strFamilies.map((item) => (
                                    <>
                                        <Select.Option
                                            key={item._id} value={item._id}>{item.name}</Select.Option >
                                    </>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} >
                    <Form.Item
                            name="linkedUser"
                            label="Select Account"
                            rules={[{ required: true, message: 'Please select account!' }]}

                        >
                            <Select size='large' style={{ ':hover': { borderColor: "black" } }}>
                                {users && users.map((item) => (
                                    <>
                                        <Select.Option
                                            key={item._id} value={item._id}>{item.name}</Select.Option >
                                    </>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="trailing_stop_loss"
                            label="Trailing Stoploss Percent"
                            rules={[{ required: true, message: 'Please input trailing sl percent!' }]}
                        >
                            <InputNumber size='large' addonAfter={<PercentageOutlined />} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="initialLots"
                            label="Lot"
                            rules={[{ required: true, message: 'Please enter lot!' }]}
                        >
                            <InputNumber size='large' addonAfter="L" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>

                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24}>
                        <Form.Item name='isActive' label="Configure for all Future Instruments" valuePropName="checked" initialValue={true}>
                            <Switch disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button className='bg-theme color-white' htmlType="submit" loading={isLoading}>
                        Add <PlusCircleFilled />
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default StrategyFutures;
