import { useEffect, useState } from "react";
import { Collapse, Table, message } from "antd";
import api from "../apis/api";
import Column from "antd/lib/table/Column";
import MyTable from "../components/MyTable";
import CollapseTable from "../components/CollapseTable";
const {Panel} = Collapse;

const Returns = () => {

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        api.kite.returns.getLnReturns().then((res) => {
            console.log(res)
            setData(res.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            message.error("Error")
        })
    }, [])

    useEffect(() => {
        if (data && data.length > 0 && columns.length < 1) {
            let keys = Object.keys(data[0])
            setColumns(keys.map((item) => {
                return (
                    {
                        title: item,
                        dataIndex: item,
                        key: item,
                        width: "20%",
                        render: (text) => String(text)
                    }
                )
            }))
        }
    }, [data])

    const renderColumns = () => {
        return (
            <>
                <Column
                    title="Trading Symbol"
                    dataIndex="trading_symbol"
                    key="trading_symbol"
                    width="30%"
                />
                <Column
                    title="Sum of Log Returns %"
                    dataIndex="SumLnReturns"
                    key="SumLnReturns"
                    width="30%"
                />
                <Column
                    title="Details"
                    key="all_documents"
                    width="30%"
                    render={(text, record) => (
                        <Collapse accordion>
                        <Panel header="Show individual trades" key="1">
                          <CollapseTable 
                          data={record.all_documents}
                          />
                        </Panel>
                      </Collapse>
                    )}
                />
            </>
        )
    }


    return (
        <Table
            loading={isLoading}

            dataSource={data}
            pagination={{
                position: 'bottom'
            }}

            className="ant-border-space">
            {
                renderColumns()
            }
        </Table>
    )
}

export default Returns