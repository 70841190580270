import React from 'react';
import { Route, Switch, useRouteMatch, NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import StrategyList from './StrategyList';
import StrategyFutureList from './StrategyFutureList';
import StrategyEtfList from './StrategyEtfList';
const StrategyMonitoring = () => {
    let { path, url } = useRouteMatch();
    return (
        <>
            <div className='nested_route_cntn'>
                <NavLink to={`${url}/stocks`} activeClassName="active-nested">Stocks</NavLink>
                <NavLink to={`${url}/etf`} activeClassName="active-nested">ETF</NavLink>
                <NavLink to={`${url}/futures`} activeClassName="active-nested">Futures</NavLink>
            </div>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/stocks`} />
                </Route>
                <Route path={`${path}/stocks`} component={StrategyList} />
                <Route path={`${path}/etf`} component={StrategyEtfList} />
                <Route path={`${path}/futures`} component={StrategyFutureList} />
            </Switch>
        </>
    )
}

export default StrategyMonitoring