import React from 'react';
import { Route, Switch, useRouteMatch, NavLink } from 'react-router-dom';
import StrategyStocks from './StrategyStocks';
import StrategyFutures from './StrategyFutures';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const Strategy = () => {
    let { path, url } = useRouteMatch();
    return (
        <>
            <div className='nested_route_cntn'>
                <NavLink to={`${url}/stocks-etfs`} activeClassName="active-nested">Stocks & ETF</NavLink>
                <NavLink to={`${url}/futures`} activeClassName="active-nested">Futures</NavLink>

            </div>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/stocks-etfs`} />
                </Route>
                <Route path={`${path}/stocks-etfs`} component={StrategyStocks} />
                <Route path={`${path}/futures`} component={StrategyFutures} />
            </Switch>
        </>
    )
}

export default Strategy