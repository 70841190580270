import api from "../../apis/api"
import { ATTEMPT_KITE_LOGIN, FETCH_USERS, INIT, LOGIN_USER, LOGOUT_USER, REGISTER_USER, UPLOAD_KITE_DETS, VERIFY_TOKEN } from "./types"

const userLogin = async(data,dispatch) => {
    try{
        const res = await api.auth.login(data);
        console.log(res)
        dispatch({type:LOGIN_USER,payload:res})
    }
    catch(error){
        console.log(error)
    }
}

const fetch_users = async (dispatch) => {
    try {
        dispatch({ type: INIT })
        const res = await api.users.fetch_users()
        console.log(res)
        dispatch({ type: FETCH_USERS, payload: res })
    }
    catch (error) {
        console.log(error)
    }
}

const userRegister = async (data,dispatch) => {
    try {
        dispatch({ type: INIT })
        const res = await api.auth.register(data);
        res.mobile = data.mobile;
        dispatch({type:REGISTER_USER,payload:res})
    } catch (error) {
        console.log(error)
    }
}

const verifyMe = async(dispatch) => {
    const res = await api.auth.me();
    console.log(res)
    dispatch({type:VERIFY_TOKEN,payload:res})
}

const saveKiteDetails = async (data, dispatch) => {
    try {
        dispatch({ type: INIT })
        const res = await api.kite.trading.uploadTradingDetails(data);
        console.log(res)
        dispatch({ type: UPLOAD_KITE_DETS, payload: res })
    }
    catch (error) {
        console.log(error)
    }
}

const logoutUser = async(dispatch) => {
    try{
        dispatch({type:LOGOUT_USER})
    }
    catch(error){
        console.log(error)
    }
}

const kiteLogin = async(data, dispatch) => {
    try{
        dispatch({type:INIT})
        const res = await api.kite.auth.performKiteLogin(data);
        dispatch({type:ATTEMPT_KITE_LOGIN,payload:res})
    }
    catch(error){
        console.log(error)
    }
}


export {userLogin, fetch_users, userRegister, saveKiteDetails, verifyMe, logoutUser,kiteLogin };