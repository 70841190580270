import { useEffect } from "react";
import MyTable from "../components/MyTable";
import { fetch_users } from "../controller/user/action";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

const Users = () => {
    const { users, msgType, msgComp, msg,isLoading } = useSelector((state) => state.user)
    const dispatch = useDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetch_users(dispatch)
    }, [])

    useEffect(() => {
        if(msgComp === "users"){
            message.open({
                type:msgType,
                content:msg
            })
        }
    },[msg,msgComp,msgType])



    return (
        <MyTable
            loading={isLoading}
            data={users}
        />
    )
}

export default Users