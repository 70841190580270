import React from 'react'
import {
    Row,
    Col,
    Card,
    Table,
} from "antd";
import { useEffect, useState } from "react";
import MyPagination from '../../components/MyPagination';
import "../../assets/styles/analysis-table.css";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '../../apis/api';
let filterObj = {

}

const WmaAnalysisTrades = () => {
    const { id } = useParams();
    console.log(id)
    const [data, setData] = useState([])
    const [confirmLoadingStatus, setConfirmLoadingStatus] = useState(false)
    // pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState()
    useEffect(() => {
        filterObj = { "_id": id }
        fetchStockEtfAnalysisTrade(1)
    }, [])

    const fetchStockEtfAnalysisTrade = (page) => {
        let pageObj = {
            pageNumber: page
        }
        api.analysis.getTradesManualEEAnalysis({ filterObj, pageObj }).then((res) => {
            setData(res.data)
            setTotalPages(res.totalRecords)
        }).catch(err => {
            console.log(err)
        })
    }


    const onPaginate = (page) => {
        console.log(page)
        setCurrentPage(page)
        setData([])
        fetchStockEtfAnalysisTrade(page)
    }

    return (
        <>
            <div class="table-wrapper">
                    <div className='table-wrapper-info'>
                       <p>Symbol : {data.trading_symbol}, Category : {data.category}</p>
                    </div>
                <table class="fl-table">
                    <thead>
                        <tr>
                            <th>SL %</th>
                            <th>Date</th>
                            <th>Entry</th>
                            <th>Exit</th>
                            <th>Log</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.tradeHistory && data.tradeHistory.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.stoplossPercent}</td>
                                    <td>{item.date}</td>
                                    <td>{item.entry}</td>
                                    <td>{item.exit}</td>
                                    <td>{item.log}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <MyPagination current={currentPage} total={totalPages} onPaginate={(page) => onPaginate(page)} />
        </>

    )
}

export default WmaAnalysisTrades