import React, { useState } from 'react';
import { Form, Input, Row, Col, Button, Card, InputNumber, Switch, message } from 'antd';
import { CalculatorFilled, PercentageOutlined, PlusCircleFilled, } from '@ant-design/icons';
import api from '../apis/api';

const StrategyFamily = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = values => {
    setIsLoading(true)
    api.kite.strategies.addStrategyFamily(values).then((res)=>{
      message.success(res.msg)
      setIsLoading(false)
      form.resetFields()
    }).catch(err => {
      message.error(err)
      setIsLoading(false)
    })
  };

  return (
    <Card
      title="Design Strategy"
      extra={<CalculatorFilled />}
    >
      <Form
        form={form}
        name="my_form"
        onFinish={onFinish}
        layout="vertical"
      // initialValues={{
      //   field1: '',
      //   field2: '',
      //   field3: '',
      //   field4: '',
      // }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24}>
            <Form.Item
              name="name"
              label="Strategy Name"
              rules={[{ required: true, message: 'Please input strategy name!' }]}
            >
              <Input type='text' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name={['fields', 'stoplossPercent']}
              label="Stoploss Percent"
              rules={[{ required: true, message: 'Please input stoploss percent!' }]}
            >
              <InputNumber size='large' addonAfter={<PercentageOutlined />} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button className='bg-theme color-white' htmlType="submit" loading={isLoading}>
            Add <PlusCircleFilled />
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default StrategyFamily;
