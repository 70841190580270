import {
    Row,
    Col,
    Card,
    Button,
    Descriptions
  } from "antd";

const HealthCheck = () => {
    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Card className="card-billing-info" bordered="false">
                    <div className="col-info">
                        <Descriptions title="First Trade">
                            <Descriptions.Item label="Instrument" span={3}>
                                TATASTEEL
                            </Descriptions.Item>
                            <Descriptions.Item label="Quantity" span={3}>
                                1
                            </Descriptions.Item>
                            <Descriptions.Item label="Exchange" span={3}>
                                NSE
                            </Descriptions.Item>
                            <Descriptions.Item label="Order Type" span={3}>
                                BUY
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                    <Col xs={24} md={12} className="">
                        {/* <Button type="primary" onClick={()=>placeTrade()}> Place Order</Button> */}
                    </Col>
                    <Button type="link" className="darkbtn">
                    </Button>
                </Card>
            </Col>
        </Row>
    )
}

export default HealthCheck