import {
    Row,
    Col,
    Card,
    Table,
} from "antd";
import { useEffect, useState } from "react";


function CollapseTable(props) {
    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (props.data && props.data.length > 0 && columns.length < 1) {
            let keys = Object.keys(props.data[0])
            setColumns(keys.map((item) => {
                return (
                    {
                        title: item,
                        dataIndex: item,
                        key: item,
                        width: "20%",
                        render : (text) => String(text)
                    }
                )
            }))
        }
    }, [props.data])



    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"

                        >
                            <div className="table-collapse">
                                <Table
                                    loading={
                                        props.loading
                                    }
                                    columns={columns}
                                    dataSource={props.data}
                                    pagination={{
                                        position: 'bottom'
                                    }}
                                    
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default CollapseTable;
