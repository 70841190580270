import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  message,
  Skeleton,
} from "antd";

import {
  FundOutlined,
  UserOutlined
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import api from "../apis/api";
import { useDispatch, useSelector } from "react-redux";
import { kiteLogin } from "../controller/user/action";
import { roundoff } from "../utils/math";

function Profile() {

  const { msgComp, msg, msgType, isLoading, iskiteLoggedIn } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})
  const [margin, setMargin] = useState({})
  
  const fetchKiteProfile = () => {
    api.kite.account.fetch_kite_profile().then((res) => {
      setProfile(res.profile)
    }).catch(err => {
      console.log(err)
    })
  }

  const getMargins = () => {
    api.kite.account.getMargins().then(res => {
      setMargin(res.margin.equity)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    fetchKiteProfile()
    getMargins()
  }, [])

  useEffect(() => {
    if (msgComp === "profile") {
      if (iskiteLoggedIn) {
        fetchKiteProfile()
        getMargins()
        message.open({
          content: msg,
          type: msgType
        })
      }
    }
  }, [iskiteLoggedIn])


  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" icon={<UserOutlined />} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{profile?.user_name ? profile.user_name : <Skeleton.Input active size='small' />}</h4>
                  <p></p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {profile?.user_name ? <></> : <Button loading={isLoading} disabled={isLoading} onClick={() => kiteLogin({ "mobile": localStorage.getItem('mobile') }, dispatch)}>Attempt Kite Login</Button>}
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            extra={<UserOutlined color="red" />}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions>
              <Descriptions.Item label="Broker" span={3}>
                {profile?.broker ? profile.broker : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
              <Descriptions.Item label="User Id" span={3}>
                {profile?.user_id ? profile.user_id : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
              <Descriptions.Item label="Usertype" span={3}>
                {profile?.user_type ? profile.user_type : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {profile?.email ? profile.email : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Funds</h6>}
            className="header-solid h-full card-profile-information"
            extra={<FundOutlined color="red" />}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions>
              <Descriptions.Item label="Net" span={3}>
                {margin?.net ? roundoff(margin.net) : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
              <Descriptions.Item label="Opening Balance" span={3}>
                {margin?.available ? roundoff(margin.available?.opening_balance) : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
              <Descriptions.Item label="Utilised" span={3}>
                {margin?.utilised ? roundoff(margin.utilised?.debits) : <Skeleton.Input active size='small' />}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Profile;
