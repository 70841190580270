// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from 'firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyChdCMkotZLyerns9vyVle07vZ-Up6mfjg",
  authDomain: "dhruti-1556a.firebaseapp.com",
  projectId: "dhruti-1556a",
  storageBucket: "dhruti-1556a.appspot.com",
  messagingSenderId: "369031151900",
  appId: "1:369031151900:web:c70eb18caa39e9e0a23ad9",
  measurementId: "G-SKM43VZ6P0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export { messaging,getToken };