import React from 'react'
import {
    Row,
    Col,
    Card,
    Table,
    Input,
    Spin,
    message,
    Checkbox,
    Button,
    Popconfirm
} from "antd";
import { useEffect, useState } from "react";
import api from "../../apis/api";
import MyPagination from '../../components/MyPagination';
import "../../assets/styles/analysis-table.css";
import { NavLink, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { sortColumn } from '../../utils/sortColumnsTable';
import { ColumnHeightOutlined, SortAscendingOutlined } from '@ant-design/icons';
import BackToTopBtn from '../../components/BackToTopBtn';
import { roundoff } from '../../utils/math';
let filterObj = {

}
let selectedIds =[]
let stopLossArr = []
const IndicesLog = () => {
    let { path, url } = useRouteMatch();
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [averageLog, setAverageLog] = useState(0)
    const [confirmLoadingStatus, setConfirmLoadingStatus] = useState(false)
    const [editMode, seteditMode] = useState(false)
    useEffect(() => {
        selectedIds = []
        stopLossArr = []
        filterObj = {"category": "index" }
        fetchStockEtfAnalysisList()
    }, [])

    const fetchStockEtfAnalysisList = () => {
        api.analysis.getAnalysisDynamicSLTrail({ filterObj }).then((res) => {
            setData(res.data)
            calculateAverage(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filterObj = {
                ...filterObj,
                "trading_symbol": { $regex: e.target.value, $options: "i" }
            }
            setData([])
            fetchStockEtfAnalysisList();
        }
    }

    const handleSort = (key) => {
        let sortedData = sortColumn(key,sortConfig,data)
        console.log(sortedData)
        setData(sortedData.sortedData)
        setSortConfig({key: sortedData.key, direction: sortedData.direction })
        selectedIds = []
        stopLossArr = []
    }

    const calculateAverage = (data) => {
        let logTotalSum = 0;
        let logLength = 0;
        for (let i=0;i<data.length;i++){
            if(data[i].isActive){
                logLength +=1;
                logTotalSum +=data[i].log
            }
        }
        setAverageLog(roundoff(logTotalSum/logLength))
    }   

    
    const handleSelectInstruments = (check, id,ind) => {
        if (selectedIds.includes(id)){
            if (check === false){
               let index  = selectedIds.indexOf(id)
               selectedIds.splice(index,1)
               let foundSlIndex = stopLossArr.findIndex((item) => item._id === id);
               console.log(foundSlIndex,id)
               stopLossArr.splice(foundSlIndex,1)
            }
        }
        else if(check === true){
            selectedIds.push(id)
            let sl = document.getElementById("trailing_stop_loss" + ind).innerText;
            let obj = {
                "_id":id,
                "fields":{
                    "trailing_stop_loss":sl
                }
            }
            stopLossArr.push(obj)
        }
    }

    const confirmStatus = (decision) => {
        if(selectedIds.length > 0){
            if(decision === 'enable'){
                updateAnalysisStatus(decision)
            }
            else if(decision === 'disable'){
                updateAnalysisStatus(decision)
            }
            else if(decision === 'slUpdate'){
                updateAnaysisStopLoss()   
            }
        }
    }

    const updateAnalysisStatus = (decision) => {
        setConfirmLoadingStatus(true)
        setData([])
        api.analysis.enableDisableDynamicSLTrailAnalysis({selectedIds,decision}).then((res)=>{
            console.log(res);
            selectedIds = []
            fetchStockEtfAnalysisList();
            setConfirmLoadingStatus(false)
            message.open({
                type: "success",
                content: "Updated successfully"
            })
        }).catch(err=> {
            console.log(err)
            selectedIds = []
            setData([])
            fetchStockEtfAnalysisList();
            setConfirmLoadingStatus(false)
            message.open({
                type: "error",
                content: "Error"
            })
        })
    }

    
    const updateAnaysisStopLoss = () => {
        api.analysis.modifydynamicSLTrailAnalyis({"data":stopLossArr}).then((res) => {
            console.log(res)
            message.success('Updated successfully')
        }).catch(err => {
            console.log(err)
        })
    }


    return (
        <>
            <Spin className='my_loader' spinning={data && data.length === 0 ? true : false} fullscreen />
            <BackToTopBtn/>
            <div className='filter_strategies_cntn_outer'>
            <div className='filter_strategies_cntn'>
            {editMode?<><Popconfirm title="Are you sure?"
                        description="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                            loading: confirmLoadingStatus,
                        }}
                        onConfirm={() => confirmStatus('disable')}
                    >
                        <Button>Disable Analysis</Button>
                    </Popconfirm>
                    <Popconfirm title="Are you sure?"
                        description="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                            loading: confirmLoadingStatus,
                        }}
                        onConfirm={() => confirmStatus('enable')}
                    >
                        <Button style={{marginLeft:"5px"}}>Enable Analysis</Button>
                    </Popconfirm>
                    <Popconfirm title="Are you sure?"
                        description="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                            loading: confirmLoadingStatus,
                        }}
                        onConfirm={() => confirmStatus('slUpdate')}
                    >
                        <Button style={{marginLeft:"5px"}}>Update StopLoss</Button>
                    </Popconfirm></>:<></>}
            </div>
            <div className='total_funds_strategy'>
            <h4>Average Log : {averageLog}</h4>
            </div>
            </div>
            <div class="table-wrapper">
            <Button style={{float:'right',background:editMode?'gold':'white',marginBottom:"10px"}} onClick={()=> {
                    seteditMode(!editMode)
                    selectedIds = []
                }}>Edit Mode</Button>
                <table class="fl-table">
                    <thead>
                        <tr>
                        {editMode ? <th>Select</th> : <></>}
                            <th>S.No</th>
                            <th>Trading Symbol     {editMode ? <></>:<SortAscendingOutlined onClick={() => handleSort('trading_symbol')} />}
                                <br />
                                <Input size="small" placeholder='Search' onKeyUp={(e) => handleSearch(e)} />
                            </th>
                            <th>Category</th>
                            <th>Trailing SL</th>
                            <th>Waiting for</th>
                            <th>Total Trades {editMode?<></>:<ColumnHeightOutlined onClick={() => handleSort('no_of_trades')} />}
                            <br /></th>
                            <th>Log {editMode?<></>:<ColumnHeightOutlined onClick={() => handleSort('log')} />}</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => {
                            return (
                                <tr key={index} style={{opacity:item.isActive?1:0.4}}>
                                       {editMode ? <td><Checkbox onChange={(e) => {
                                        handleSelectInstruments(e.target.checked, item._id,index)
                                    }} /></td> : <></>}
                                    <td>{index + 1}</td>
                                    <td className='fl-trading-symbol'><NavLink to={`/dynamicSLEvaluation/indices/${item._id}`}>{item.trading_symbol}</NavLink></td>
                                    <td>{item.category}</td>
                                    <td id={"trailing_stop_loss" + index} contentEditable suppressContentEditableWarning={true}>{item.trailing_stop_loss}</td>
                                    <td>{item.decision}</td>
                                    <td>{item.no_of_trades}</td>
                                    <td>{item.log}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>

    )
}

export default IndicesLog