import { ArrowUpOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

const BackToTopBtn = () => {
  return (
   <Button className='scrolltoBtns scrollToTopBtn' onClick={()=>{
    window.scrollTo(0, 0);
   }}>
    <ArrowUpOutlined/>
   </Button>
  )
}

export default BackToTopBtn