import React from 'react'
import '../assets/styles/sidebar-switch.css'
const SidebarSwitch = (props) => {
    return (
        <>
        <div className='sidebar-switch-cntn' style={props?.style}>
            <div className="toggle-wrapper">
                <input className="toggle-checkbox" type="checkbox" onClick={()=>props.click()}/>
                    <div className="toggle-container">
                        <div className="toggle-button">
                            <div className="toggle-button-circles-container">
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                                <div className="toggle-button-circle"></div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </>
    )
}

export default SidebarSwitch