import React, { useEffect } from "react";
import {
    Layout,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    message
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import { useDispatch, useSelector } from "react-redux";
import { saveKiteDetails } from "../controller/user/action";
import { useForm } from "antd/lib/form/Form";
const { Title } = Typography;
const { Content } = Layout;

const FillDetails = () => {
    const dispatch = useDispatch();
    const [form] = useForm();
    const mobile = (new URLSearchParams((window.location.search)).get('mobile'));
    const { isLoading, msg,msgType,msgComp } = useSelector((state) => state.user)
    const onFinish = (values) => {
        values.mobile = mobile
        console.log("Success:", values);
        saveKiteDetails(values, dispatch)
    }
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    }

    useEffect(()=>{
        if(msg === "details added"){
            form.resetFields();
        }   
        if(msgComp === "fill-details"){
            message.open({
                content:msg,
                type:msgType
            })
        }
    },[msg])

    return (
        <>
            <Layout className="layout-default layout-signin">
                <Content className="signin">
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 6, offset: 2 }}
                            md={{ span: 12 }}
                        >
                            <Title className="mb-15">Account Details</Title>
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                form={form}

                                layout="vertical"
                                className="row-col"
                            >
                                <Form.Item
                                    className="username"
                                    label="User Id"
                                    name="userId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your user id!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="User Id" />
                                </Form.Item>

                                <Form.Item
                                    className="username"
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Password" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="Api Key"
                                    name="apiKey"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your api key!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Api Key" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="Api Secret"
                                    name="apiSecret"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your api secret!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Api Secret" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="Totp Secret"
                                    name="totpSecret"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your totp secret!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Totp Secret" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%" }}
                                    >
                                        Save Details
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col
                            className="sign-img"
                            style={{ padding: 12 }}
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}
                            md={{ span: 12 }}
                        >
                            <img src={signinbg} alt="" />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}

export default FillDetails
