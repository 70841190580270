import { ATTEMPT_KITE_LOGIN, FETCH_USERS, INIT, LOGIN_USER, LOGOUT_USER, REGISTER_USER, UPLOAD_KITE_DETS, VERIFY_TOKEN } from "./types";
const initialState = {
    users: [],
    isAuthenticated: false,
    name: "",
    msg: "",
    isLoading: false,
    mobile: "",
    msgType: "",
    iskiteLoggedIn: false,
    msgComp: ""
}

const userReducer = (state = initialState, action) => {
    var res;
    switch (action.type) {
        case INIT:
            return {
                ...state, isLoading: true
            }
        case LOGIN_USER:
            res = action.payload;
            if (res?.msg === "success") {
                localStorage.setItem('token', res.token)
                localStorage.setItem('mobile', res.mobile)
                return {
                    ...state, isAuthenticated: true, msg: "success", msgType: "success", mobile: res.mobile
                }
            }
            else if (res?.msg === "Password did not match!") {
                return {
                    ...state, msg: res.msg, msgType: "error"
                }
            }
            else if (res?.msg === "error") {
                return {
                    ...state, msg: "error", msgType: "error"
                }
            }
            else {
                return {
                    ...state, msg: res?.msg, msgType: "info"
                }
            }
        case VERIFY_TOKEN:
            res = action.payload
            if (res.msg === "success") {
                return {
                    ...state, isAuthenticated: true, msg: "success", mobile: res.mobile
                }
            }
            return state
            break;
        case LOGOUT_USER:
            localStorage.clear()
            window.location.assign('/')
            return {
                ...state, isAuthenticated: false, name: "", msg: "", mobile: ""
            }
        case REGISTER_USER:
            res = action.payload;
            if (res?.msg === "success") {
                return {
                    ...state,
                    mobile: res.mobile,
                    msg: "success",
                    isLoading: false,
                    msgType: "success"
                }
            }
            else {
                return {
                    ...state, msg: res.msg, msgType: "error", isLoading: false
                }
            }
        case FETCH_USERS:
            res = action.payload;
            if (res?.msg === "users fetched") {
                return {
                    ...state,
                    users: res.users,
                    msgComp: "users",
                    isLoading: false,
                    msgType: "success",
                    msg: res.msg
                }
            }
            else {
                return {
                    ...state, msg: res.msg, isLoading: false, msgComp: "users", msgType: "info",
                }
            }
        case UPLOAD_KITE_DETS:
            res = action.payload;
            if (res?.msg === "details added") {
                return {
                    ...state,
                    isLoading: false,
                    msg: res.msg,
                    msgType: "success",
                    msgComp: "fill-details"
                }
            }
            else {
                return {
                    ...state, msg: res.msg, isLoading: false, msgType: "info", msgComp: "fill-details"
                }
            }
        case ATTEMPT_KITE_LOGIN:
            res = action.payload;
            if (res?.msg === "success") {
                return {
                    ...state,
                    iskiteLoggedIn: true,
                    isLoading: false,
                    msg: "Kite login successful",
                    msgType: "success",
                    msgComp: "profile"
                }
            }
            else if (res?.msg === "error") {
                return {
                    ...state,
                    iskiteLoggedIn: false,
                    isLoading: false,
                    msg: "error",
                    msgType: "error",
                    msgComp: "profile"
                }
            }
            else {
                return {
                    ...state,
                    iskiteLoggedIn: false,
                    isLoading: false,
                    msg: "",
                    msgType: "",
                    msgComp: "profile"
                }
            }
        default:
            return state
    }
}

export default userReducer

