import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Billing from "./pages/Billing";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Monitoring from "./pages/Monitoring";
import FillDetails from "./pages/FillDetails";
import HealthCheck from "./pages/HealthCheck";
import BusinessStrategy from "./pages/BusinessStrategy";
import AnalysisBackTesting from "./pages/AnalysisBackTesting"
import Users from "./pages/Users";
import StrategyFamily from "./pages/StrategyFamily";
import Returns from "./pages/Returns";
import Strategy from "./pages/Strategy/Strategy";
import StrategyList from "./pages/StrategyMonitoring/StrategyList";
import StrategyMonitoring from "./pages/StrategyMonitoring/StrategyMonitoring";
import { useEffect } from "react";
import { messaging, getToken } from './services/notifications/firebase/index';
import api from "./apis/api";
import Logs from "./pages/Logs";
import LogEvaluation from "./pages/LogEvaluation";
import Trades from "./pages/LogEvaluation/Trades";
import DynamicTrailSLLogEvaluation from "./pages/DynamicSLTrailEvaluation";
import TradesDynamicSLTail from "./pages/DynamicSLTrailEvaluation/TradesDynamicSLTail";
import WmaAnalysis from "./pages/WmaAnalysis";
import WmaAnalysisTrades from "./pages/WmaAnalysis/Trades";
function App() {

  useEffect(() => {
    handleTokenRefresh();
  }, [])


  const handleTokenRefresh = async () => {
    try {
      const currentToken = await getToken(messaging)
      if (currentToken) {
        console.log('Token:', currentToken);
        // Send the token to your backend to store or use it
        sendTokenToServer(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error('Error retrieving token:', err);
    }
  };

  const sendTokenToServer = (token) => {
    api.notifications.web_push.subscribe({ token }).then((res) => {
      console.log('Subscription stored:', res);
      api.notifications.web_push.sendPushNotification({ token }).then((res) => {
        console.log('Subscription stored:', res);
      }).catch(error => {
        console.error('Failed to store subscription:', error);
      });
    }).catch(error => {
      console.error('Failed to store subscription:', error);
    });
  }


  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('ServiceWorker registration successful:', registration);
        })
        .catch((error) => {
          console.error('ServiceWorker registration failed:', error);
        });
    } else {
      console.log('Service workers are not supported!');
    }
  }, [])

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/fill-details" component={FillDetails} />
        {
          localStorage.getItem('token') ?
            <Main>
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/strategy-family" component={StrategyFamily} />
              <Route path="/strategy" component={StrategyMonitoring} />
    
              <Route path="/evaluation" component={LogEvaluation} />
              <Route path="/evaluation/stocks/:id" component={Trades} />
              <Route path="/evaluation/etf/:id" component={Trades} />
              <Route path="/evaluation/indices/:id" component={Trades} />
              <Route path="/dynamicSLEvaluation" component={DynamicTrailSLLogEvaluation} />
              <Route path="/dynamicSLEvaluation/stocks/:id" component={TradesDynamicSLTail} />
              <Route path="/dynamicSLEvaluation/etf/:id" component={TradesDynamicSLTail} />
              <Route path="/dynamicSLEvaluation/indices/:id" component={TradesDynamicSLTail} />
              <Route path="/wmaAnalysis" component={WmaAnalysis} />
              <Route path="/wmaAnalysis/stocks/:id" component={WmaAnalysisTrades} />
              <Route path="/wmaAnalysis/etf/:id" component={WmaAnalysisTrades} />
              <Route path="/wmaAnalysis/indices/:id" component={WmaAnalysisTrades} />
              <Route exact path="/health-check" component={HealthCheck} />
              <Route exact path="/business-strategy" component={BusinessStrategy} />
              <Route exact path="/analysis-backtesting" component={AnalysisBackTesting} />
              <Route exact path="/monitoring" component={Monitoring} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/log-n-return" component={Returns} />
              <Route exact path="/logs" component={Logs} />
              <Route path="/new-strategy" component={Strategy} />
            </Main> : <Redirect to="/" />
        }
      </Switch>
    </div>
  );
}

export default App;
