import React, { useEffect, useState } from 'react';
import api from '../apis/api'; // Assuming you have configured Axios instance in api.js
import BackToTopBtn from '../components/BackToTopBtn';
import JumpToBottomBtn from '../components/JumpToBottomBtn';
import { Spin } from 'antd';

const Logs = () => {
    const [logData, setLogData] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [marginL, setMarginL] = useState()

    useEffect(() => {
        setIsLoading(true)
        getStrategyLogs();
        setMarginL(document.getElementById('log_cntn').clientWidth / 2)
    }, []);

    const getStrategyLogs = () => {
        api.logs.getStrategyLogs()
            .then((res) => {
                const data = res.data;
                const reversedData = reverseMultilineString(data.stdout);
                setLogData(reversedData);
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err);
            });
    };

    const reverseMultilineString = (str) => {
        const lines = str.split('\n');
        const reversedLines = lines.reverse();
        const reversedString = reversedLines.join('\n');
        return reversedString;
    };


    return (
        <div id='log_cntn'>
            <pre>{logData}</pre>
            <BackToTopBtn />
            {
                isLoading ? <div className='spin_log_cntn' style={{ marginLeft:marginL}}><Spin size='large' /></div> : logData.length < 1 ? <>No logs found</> : <></>
            }
        </div>
    );
};

export default Logs;
